@mixin titre1 {
  font-family: $font-family-base;
  font-size: $title1;
  font-weight: 300;
  line-height: 120%;
}

@mixin titre2 {
  font-family: $font-family-base;
  font-size: $title2;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 120%;
}

@mixin titre3 {
  font-family: $font-family-base;
  font-size: $title3;
  font-weight: 300;
  line-height: 120%;
}

@mixin text {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: 120%;
}

@mixin smalltext {
  font-family: $font-family-base;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: 120%;
}

@mixin rotateRight {
  animation: scaleRotateRightEffect 2s linear;
}

@mixin rotateLeft {
  animation: scaleRotateLeftEffect 2s linear;
}

@keyframes scaleRotateRightEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes scaleRotateLeftEffect {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(-180deg);
  }
  100% {
    transform: scale(1) rotate(-360deg);
  }
}


